











































































































































import Vue from 'vue'
import Component from "vue-class-component"
import { customer } from '@/store'
import { Prop } from "vue-property-decorator"
import ProductsSetPopover from "@/components/products/ProductsSetPopover.vue"
import { getFullImageUrl } from '@/utils/functions'
import { 
  createOrder, getDeliveryCities, getDeliveryStreets, 
  getDeliveryWarehouses, getUkrposhtaDeliveryCities, 
  getUkrposhtaDeliveryWarehouses 
} from '@/utils/customerApi'
import Order, { emptyOrder } from '@/models/order'
import City, { emptyCity } from '@/models/city'
import Street, { emptyStreet } from '@/models/street'
import Warehouse, { emptyWarehouse } from '@/models/warehouse'
import { debounce } from 'lodash';
import OrderProduct from '@/models/orderProduct'
import OrderProductsTable from '@/components/orders/OrderProductsTable.vue'
import PaymentType from '@/models/paymentType'
import { deliveryServicesOptions } from '@/utils/constants'

@Component({
  components: {
    ProductsSetPopover,
    OrderProductsTable
  }
})
export default class OrderForm extends Vue {
  @Prop() publicUrl!: string
  loading = true
  order: Order = emptyOrder()
  
  getFullImageUrl = getFullImageUrl
  customer = customer

  submitting = false
  valid = false
  attemptedToSumbit = false

  cities: Array<City> = []
  streets: Array<Street> = []
  warehouses: Array<Warehouse> = []
  warehousesLoading = false
  debounceSearchCity: any
  debounceSearchStreet: any

  deliveryServicesOptions = deliveryServicesOptions
  deliveryServices: Array<string> = []

  deliveryServiceChanged() {
    // @ts-ignore
    this.order.cityDict = null
    this.warehouses = []
    this.cities = []
    this.clearWarehouse()
    this.setDefaultPaymentType()
  }

  get retailerPage() {
    return customer.retailerPage
  }

  get filteredPaymentTypes() {
    let _paymentTypes: Array<PaymentType> = []
    if (this.retailerPage) {
      this.retailerPage.paymentTypes.forEach((paymentType: PaymentType) => {
        if (this.order.deliveryService == 'nova_poshta' && paymentType.novaPoshta) {
          _paymentTypes.push(paymentType)
        } else if (this.order.deliveryService == 'ukr_poshta' && paymentType.ukrPoshta) {
          _paymentTypes.push(paymentType)
        }
      });
    }
    return _paymentTypes
  }

  showPaymentType(paymentType: PaymentType) {
    let show = false
    if (this.order.deliveryService == 'nova_poshta' && paymentType.novaPoshta) {
      show = true
    } else if (this.order.deliveryService == 'ukr_poshta' && paymentType.ukrPoshta) {
      show = true
    }
    return show
  }

  setPaymentType(paymentType: PaymentType) {
    this.order.paymentType = paymentType
  }

  get total() {
    var _total = 0
    this.customer.products.forEach((product: OrderProduct) => {
      if (product.price)
        _total += product.price * product.amount
    });
    return _total
  }

  orderProductChanged() {
    customer.setOrder(customer.order)
  }

  removeProduct(index: number) {
    customer.products.splice(index, 1)
    customer.setProducts(customer.products)
    this.$forceUpdate()
  }

  created() {
    this.debounceSearchCity = debounce(this.searchCity, 500)
    this.debounceSearchStreet = debounce(this.searchStreet, 500)
  }

  validateField(field: string, override?: boolean) {
    if (!this.attemptedToSumbit && !override) {
      return true
    }
    var valid = false
    if (field == 'name') {
      valid = this.order.name ? true : false
    } else if (field == 'phone') {
      valid = /^(\+380[0-9]{9}|380[0-9]{9}|0[0-9]{9})$/.test(this.order.phone)
    } else if (field == 'city') {
      valid = true
      if (!this.order.city)
        valid = false
    } else if (field == 'warehouse') {
      if (this.order.homeDelivery) {
        valid = true
      } else {
        valid = this.order.warehouse.number ? true : false
      }
    } else if (field == 'street') {
      if (!this.order.homeDelivery) {
        valid = true
      } else {
        valid = this.order.street ? true : false
      }
    } else if (field == 'houseNumber') {
      if (!this.order.homeDelivery) {
        valid = true
      } else {
        valid = this.order.houseNumber ? true : false
      }
    }
    console.log(field, valid)
    return valid
  }

  validate() {
    var validationResults = []
    validationResults.push(this.validateField('name'))
    validationResults.push(this.validateField('phone'))

    if (this.order.deliveryService == 'nova_poshta') {
      validationResults.push(this.validateField('city'))
      validationResults.push(this.validateField('warehouse'))
      validationResults.push(this.validateField('street'))
      validationResults.push(this.validateField('houseNumber'))
    } else if (this.order.deliveryService == 'ukr_poshta') {
      validationResults.push(this.validateField('city'))
      validationResults.push(this.validateField('warehouse'))
    }
    this.valid = validationResults.every((element) => element)
    console.log("validationResults", validationResults)
    console.log("valid", this.valid)
  }

  get filteredDeliveryServicesOptions() {
    var _options: Array<any> = []
    this.deliveryServicesOptions.forEach(option => {
      if (this.deliveryServices.includes(option.value)) {
        _options.push(option)
      }
    })
    
    return _options
  }

  get warehouseLabel() {
    if (this.order.homeDelivery && this.order.deliveryService == 'ukr_poshta') {
      return this.$t('Отделение почты (ближайшее к адресу)')
    } else {
      return this.$t('Отделение почты')
    }
  }

  async setCity(city: City) {
    var cityDict = emptyCity()

    cityDict.titleRu = city.titleRu
    cityDict.titleUa = city.titleUa
    this.order.cityDict = cityDict
    this.order.city = city.titleUa
    this.order.cityRef = city.ref
    this.clearWarehouse()
    await this.getWarehouses()
  }

  async setStreet(street: Street) {
    var streetDict = emptyStreet()

    streetDict.titleRu = street.titleRu
    streetDict.titleUa = street.titleUa
    this.order.streetDict = streetDict

    this.order.street = street.titleRu
    this.order.streetRef = street.ref
  }

  async searchStreet(value: string) {
    this.streets = await getDeliveryStreets(this.order.city, this.order.cityRef, value)
  }

  async openWarehouses() {
    if (this.warehouses.length == 0) {
      await this.getWarehouses()
    }
  }

  async getWarehouses() {
    if(this.order.city) {
      this.warehousesLoading = true
      if (this.order.deliveryService == 'nova_poshta') {
        this.warehouses = await getDeliveryWarehouses(this.order.city)
      } else {
        this.warehouses = await getUkrposhtaDeliveryWarehouses(this.order.cityRef)
      }
      this.warehousesLoading = false
    }
  }

  async searchCity(value: string) {
    if (!value) {
      return
    }
    if (this.order.deliveryService == 'nova_poshta') {
      this.cities = await getDeliveryCities(value, this.order.homeDelivery)
    } else {
      this.cities = await getUkrposhtaDeliveryCities(value)
    }
  }

  clearWarehouse() {
    this.order.warehouse = emptyWarehouse()
    this.order.warehouseNumber = ""
    this.order.warehouseTitleRu = ""
    this.order.warehouseTitleUa = ""
    this.order.warehouseRef = ""
    this.order.warehouseCategory = ""
  }

  setWarehouse(warehouse: Warehouse) {
    this.order.warehouse = warehouse
    this.order.warehouseNumber = warehouse.number
    this.order.warehouseTitleRu = warehouse.titleRu
    this.order.warehouseTitleUa = warehouse.titleUa
    this.order.warehouseRef = warehouse.warehouseRef
    this.order.warehouseCategory = warehouse.category
  }

  translate(str: string) {
    return this.$t(str) as string
  }

  loadDeliveryServices() {
    if (this.retailerPage) {
      if (this.retailerPage.novaPoshta) {
        this.deliveryServices.push('nova_poshta')
      }
      if (this.retailerPage.ukrPoshta) {
        this.deliveryServices.push('ukr_poshta')
      }
    }
  }

  async submit() {
    this.attemptedToSumbit = true
    this.validate()
    if (this.valid) {  
      this.submitting = true
      try {
        var order = await createOrder(this.publicUrl, this.order)
      } catch (error) {
        this.$notify({
          group: 'br',
          text: this.translate('Ошибка добавления заказа. Пожалуйста, проверьте правильность заполнения и повторите попытку.'),
          type: 'error'
        })
        this.submitting = false
        return
      }
      this.$notify({
        group: 'br',
        text: this.translate('Заказ успешно добавлен!'),
        type: 'success'
      })
      customer.clearProducts()
      this.submitting = false
      this.$router.push(`/${this.publicUrl}/orders/${order.id}`)
    } else {
      this.$notify({
        group: 'br',
        text: this.translate('Пожалуйста, заполните все обязательные поля.'),
        type: 'error'
      });
    }
  }

  setDefaultPaymentType() {
    this.order.paymentType = this.filteredPaymentTypes[0]
  }

  async mounted() {
    this.loading = true
    await customer.getRetailerPage(this.publicUrl)
    this.order.products = customer.products
    this.setDefaultPaymentType()
    this.loadDeliveryServices()
    this.loading = false
  }
}
