import City from "./city";
import OrderProduct from "./orderProduct";
import PaymentType from "./paymentType";
import Street from "./street";
import Warehouse from "./warehouse";

export const emptyOrder = (): Order => ({
  id: undefined,
  name: "",
  phone: "",
  products: [],
  homeDelivery: false,
  deliveryService: "nova_poshta",
  city: "",
  cityRef: "",
  street: "",
  streetRef: "",
  warehouse: <Warehouse>{},
  warehouseNumber: "",
  warehouseTitleRu: "",
  warehouseTitleUa: "",
  warehouseRef: "",
  warehouseCategory: "",
  paymentType: null
})

export default interface Order {
  id?: number
  name: string
  phone: string
  products: Array<OrderProduct>
  homeDelivery: boolean
  deliveryService: string
  cityDict?: City
  streetDict?: Street
  houseNumber?: string
  flatNumber?: string
  city: string
  cityRef: string
  street: string
  streetRef: string
  warehouse: Warehouse
  warehouseNumber: string
  warehouseTitleRu: string
  warehouseTitleUa: string
  warehouseRef: string
  warehouseCategory: string
  paymentType: PaymentType | null
  ttn?: string
}