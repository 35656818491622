





import Vue from 'vue'
import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import { getFullImageUrl } from '@/utils/functions'

@Component
export default class ShowImageModal extends Vue {
  @Prop() filename!: string
  getFullImageUrl = getFullImageUrl
}
