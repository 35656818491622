

















import Vue from "vue"
import Component from "vue-class-component"
import { customer } from "@/store"

@Component
export default class Navbar extends Vue {
  get productsCount() {
    return customer.products.length
  }

  get customer() {
    return customer
  }
}
