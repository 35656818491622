









































import Vue from 'vue'
import Component from "vue-class-component"
import { getCategories } from '@/utils/customerApi'
import Category from '@/models/category'
import { Prop, Watch } from "vue-property-decorator"
import BluredBackground from './BluredBackground.vue'
import CategoriesData from '@/models/categoriesData'
import { perPageOptions } from "@/utils/constants"
import { customer } from '@/store'
import { getFullImageUrl } from '@/utils/functions'
import RetailerPage from '@/models/retailerPage'

@Component({
  components: {
    BluredBackground
  }
})
export default class PublicCategoriesList extends Vue {
  @Prop() publicUrl!: string
  loading = true
  categories: Array<Category> = []
  categoriesData!: CategoriesData
  searchQuery = ""
  currentPage = 1
  perPageOptions = perPageOptions
  getFullImageUrl = getFullImageUrl

  get retailerPage() {
    return customer.retailerPage
  }
  
  get customer() {
    return customer
  }

  @Watch('searchQuery')
  async watchSearchQuery() {
    this.currentPage = 1
    await this.getCategories()
  }
  
  async setPerPageOption(perPage: number) {
    customer.setCategoriesPerPage(perPage)
    this.currentPage = 1
    await this.getCategories()
  }

  async currentPageChanged(page: number) {
    this.currentPage = page
    await this.getCategories()
  }

  async mounted() {
    await this.getCategories()
  }

  openCategory(categoryId: number) {
    this.$router.push(this.categoryLink(categoryId))
  }

  categoryLink(categoryId: number) {
    return `/${this.publicUrl}/c/${categoryId}`
  }

  async getCategories() {
    this.loading = true
    this.categoriesData = await getCategories(this.publicUrl, 0, this.currentPage, customer.categoriesPerPage, this.searchQuery)
    this.categories = this.categoriesData.results
    this.loading = false
  }
}
