













































import Vue from 'vue'
import Component from "vue-class-component"
import Categories from "./Categories.vue"
import Gallery from './Gallery.vue'
import { Prop, Watch } from "vue-property-decorator"
import { customer } from '@/store'
import PaymentTypesModal from '@/components/orders/PaymentTypesModal.vue'
import DeliveryServicesModal from '@/components/orders/DeliveryServicesModal.vue'

@Component({
  components: {
    Categories,
    Gallery,
    PaymentTypesModal,
    DeliveryServicesModal
  }
})
export default class ProductsNavigation extends Vue {
  @Prop() categoryId!: number
  @Prop() tabType!: string
  @Prop() publicUrl!: string
  loading = true
  descriptionLimit = 100
  showMoreDescription = false
  realTabType = ""

  toggleShowMoreDescription() {
    this.showMoreDescription = !this.showMoreDescription
  }

  openCategory(categoryId: number) {
    this.categoryId = categoryId
    this.realTabType = 'products'
  }

  productsTabClick() {
    this.realTabType = 'products'
    this.$router.push(`/${this.publicUrl}/c/-1`)
  }

  @Watch('realTabType')
  watchTabType() {
    if (this.realTabType == 'categories') {
      this.$router.push(`/${this.publicUrl}`)
    } 
  }

  get retailerPage() {
    return customer.retailerPage
  }

  async mounted() {
    this.loading = true
    await customer.getRetailerPage(this.publicUrl)
    if (customer.retailerPage) {
      document.title = customer.retailerPage.name
      if (customer.retailerPage.avatarFilename) {
        var favicon = document.getElementById("favicon");
        (favicon as any).href = this.getFullImageUrl(customer.retailerPage.avatarFilename)
      }
    }
    // this.$forceUpdate()
    // this.$nextTick()
    if (!this.retailerPage || !this.retailerPage.publicUrl) {
      this.$router.push('/')
    }
    this.realTabType = this.tabType
    if (!this.categoryId) {
      this.categoryId = -1
    }
    var productsDisplayMode = (this.$route.query.mode as string) 
    if (productsDisplayMode) {
      customer.setProductsDisplayMode(productsDisplayMode)
    }
    if (!customer.productsDisplayMode) {
      customer.setProductsDisplayMode('gallery')
    }
    this.loading = false
  }

  getFullImageUrl(filename: string) {
    return `${process.env.VUE_APP_BASE_URL}/pages/uploads/${filename}`
  }
}
