



























import RetailerPageContact from '@/models/retailerPageContact'
import { customer } from '@/store'
import Vue from 'vue'
import Component from "vue-class-component"

@Component
export default class ContactsModal extends Vue {

  phones: Array<RetailerPageContact> = []
  links: Array<RetailerPageContact> = []
  locations: Array<RetailerPageContact> = []

  created() {
    if (customer.retailerPage) {
      customer.retailerPage.contacts.forEach((contact: RetailerPageContact) => {
        if (contact.type == 'phone') {
          this.phones.push(contact)
        } else if (contact.type == 'link') {
          this.links.push(contact)
        } else if (contact.type == 'location') {
          this.locations.push(contact)
        } 
      });
    }
  }
}
