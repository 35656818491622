



















import Vue from 'vue'
import Component from "vue-class-component"
import { Prop } from "vue-property-decorator"
import ChildProduct from '@/models/childProduct'
import BluredBackground from './BluredBackground.vue'
import RetailerPage from '@/models/retailerPage'
import { getFullImageUrl } from '@/utils/functions'
import { customer } from '@/store'

@Component({
  components: {
    BluredBackground
  }
})
export default class ProductsSetPopover extends Vue {
  @Prop() childProduct?: ChildProduct
  @Prop({default: false}) hideSizes?: boolean

  getFullImageUrl = getFullImageUrl
  
  get retailerPage() {
    return customer.retailerPage
  }

  openProduct(productId: number) {
    let _productLink = this.productLink(productId)
    if (_productLink)
      this.$router.push(_productLink)
  }

  productLink(productId: number) {
    if (this.retailerPage) {
      return `/${this.retailerPage.publicUrl}/p/${productId}`
    }
  }

  sizeCellColor(realCountDropshipper: number) {
    return realCountDropshipper > 0 ? 'table-success': ''
  }

  realCountDropshipper(realCountDropshipper: number) {
    if (this.retailerPage && this.retailerPage.hiddenStock) {
      if (realCountDropshipper > 0) {
        return this.retailerPage.stockAvailableText
      } else {
        return this.retailerPage.stockUnavailableText
      }
    } else {
      return realCountDropshipper
    }
  }
}
