import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import vSelect from 'vue-select'
import "vue-select/dist/vue-select.css"
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import Notifications from 'vue-notification'
import { fab } from '@fortawesome/free-brands-svg-icons'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import VueClipboard from 'vue-clipboard2'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import Clipboard from 'v-clipboard'
import VueCookies from "vue-cookies-ts"
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import '@/assets/css/vue-multiselect.css'
import draggable from 'vuedraggable'
import 'vue2-datepicker/index.css'
import VueObserveVisibility from 'vue-observe-visibility'
const linkify = require('vue-linkify')
import VueI18n from 'vue-i18n'
import { messages, defaultLocale } from "@/i18n";

// import { StreamBarcodeReader } from "vue-barcode-reader";

Vue.use(VueI18n)

const i18n = new VueI18n({
  messages,
  locale: defaultLocale,
  fallbackLocale: defaultLocale
});

Vue.directive('linkified', linkify)

Vue.use(require('vue-expandable-image'))
Vue.use(VueObserveVisibility)

Vue.component('draggable', draggable)
Vue.component('multiselect', Multiselect)
 
Vue.use(VueCookies)
Vue.use(Clipboard)

Vue.component('v-select', vSelect)
Vue.use(require('vue-moment'))

// @ts-ignore
library.add(fas)
// @ts-ignore
library.add(fab)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(BootstrapVue)
Vue.use(Notifications)
Vue.use(VueClipboard)

Vue.config.productionTip = false
import '@/assets/css/base.css'
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

export { i18n };