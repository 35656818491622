

















































































































import Vue from 'vue'
import Component from "vue-class-component"
import { getCategories, getCategory, getProducts } from '@/utils/customerApi'
import { Prop, Watch } from "vue-property-decorator"
import Category, { emptyCategory } from '@/models/category'
import { perPageOptions } from "@/utils/vendor/constants"
import ProductsSetPopover from "@/components/products/ProductsSetPopover.vue"
import BluredBackground from './BluredBackground.vue'
import CategoriesData from '@/models/categoriesData'
import Product from '@/models/product'
import ProductSize from '@/models/productSize'
import ProductsData from '@/models/productsData'
import { customer } from "@/store"
import { getFullImageUrl } from '@/utils/functions'
const moment = require('moment')
import axios, { CancelTokenSource } from 'axios'

@Component({
  components: {
    ProductsSetPopover,
    BluredBackground
  }
})
export default class Gallery extends Vue {
  @Prop() publicUrl!: string
  @Prop() categoryId!: number
  
  category: Category | null = null
  categories: Array<Category> = []
  subcategories: Array<Category> = []
  subcategoriesData!: CategoriesData
  searchQuery = ""

  loading = true
  productsData?: ProductsData 
  productId: number | null = null
  currentPage = 1
  perPageOptions = perPageOptions
  getFullImageUrl = getFullImageUrl

  cancelToken?: CancelTokenSource

  tableFields = [ 
    { key: 'image', label: '' },
    { key: 'title', label: this.$t('Название') },
    { key: 'sku', label: this.$t('Артикул') },
    { key: 'price', label: this.$t('Цена'), variant: 'warning' },
    { key: 'availability', label: this.$t('Наличие') },
    { key: 'info', label: '' },
  ]

  translate(str: string) {
    return this.$t(str) as string
  }

  get retailerPage() {
    return customer.retailerPage
  }

  get moment() {
    return moment
  }

  get customer() {
    return customer
  }

  async setProductsDisplayMode(value: string) {
    customer.setProductsDisplayMode(value)
    await this.getProducts()
  }

  sizeCellColor(realCountDropshipper: number) {
    return realCountDropshipper > 0 && this.retailerPage && this.retailerPage.highlightAvailability ? `table-${this.retailerPage.buttonsColor}`: ''
  }

  realCountDropshipper(product: Product, size: ProductSize) {
    var realCountDropshipper = size.realCountDropshipper
    if (this.retailerPage && (this.retailerPage.hiddenStock || product.hiddenStock)) {
      if (realCountDropshipper && realCountDropshipper > 0) {
        return this.retailerPage.stockAvailableText
      } else {
        return this.retailerPage.stockUnavailableText
      }
    } else {
      return realCountDropshipper
    }
  }

  get noProductsText() {
    if (this.category) {
      if (this.category.id != -1) {
        return this.$t("Нет товаров в этой категории")
      } else if (this.searchQuery) {
        return this.$t("Не найдено")
      }
    }
    return this.$t("Нет товаров") 
  }

  get noProductsIcon() {
    if (this.searchQuery) {
      return "search"
    } else {
      return "box-open"
    }
  }

  @Watch('searchQuery')
  async watchSearchQuery() {
    this.currentPage = 1
    await this.getProducts()
  }

  async currentPageChanged(page: number) {
    this.currentPage = page
    await this.getProducts()
    window.scrollTo(0,0)
  }

  async setPerPageOption(perPage: number) {
    customer.setProductsPerPage(perPage)
    this.currentPage = 1
    await this.getProducts()
  }

  openProduct(productId: number) {
    this.$router.push(this.productLink(productId))
  }

  productLink(productId: number) {
    return `/${this.publicUrl}/p/${productId}`
  }

  async setCategory(category: Category, getProducts: boolean = true) {
    if (category.id !== undefined) {
      this.category = category
    }
    this.$router.push(`/${this.publicUrl}/c/${category.id}`)
    if (getProducts)
      await this.getProducts()
  }

  async filterChanged() {
    await this.getProducts()
  }

  openSubcategory(categoryId: number) {
    if (categoryId == -1) {
      this.$router.push(`/${this.publicUrl}`)
    } else {
      this.$router.push(`/${this.publicUrl}/c/${categoryId}`)
    }
    window.scrollTo(0,0)
  }

  async mounted() {
    var allCategory = emptyCategory()
    allCategory.id = -1
    allCategory.title = this.translate("Все категории")

    var noCategory = emptyCategory()
    noCategory.id = 0
    noCategory.title = this.translate("Без категории")

    if (this.categoryId > 0) {
      var category = await getCategory(this.publicUrl, this.categoryId)
      await this.setCategory(category, false)
    } else if (this.categoryId == 0) {
      await this.setCategory(noCategory, false)
    }

    if (!this.category) {
      await this.setCategory(allCategory, false)
    }
    if (this.categoryId > 0) {
      this.subcategoriesData = await getCategories(this.publicUrl, this.categoryId)
      this.subcategories = this.subcategoriesData.results
    }
    await this.getProducts()
  }

  get breadcrumbItems() {
    var _breadcrumbItems = []
    if (this.category) {
      var parent: any = this.category
      while(parent) {
        _breadcrumbItems.unshift(parent)
        parent = parent.parent
      }
    }
    return _breadcrumbItems
  }

  deleteProductModal(productId: number) {
    this.productId = productId
  }

  async getProducts() {
    this.loading = true
    if (this.category && this.category.id !== undefined) {
      if (this.cancelToken)
        this.cancelToken.cancel()

      this.cancelToken = axios.CancelToken.source()

      this.productsData = await getProducts(
        this.publicUrl, this.currentPage, customer.productsPerPage, 
        this.category.id, this.searchQuery, 
        customer.productsDisplayMode, this.cancelToken.token
      )
    }
    this.loading = false
  }
}
