import Image from './productImage';

export const emptyCategory = (): Category => ({
  id: undefined,
  title: "",
})

export default interface Category {
  id?: number
  title: string
  image?: Image
  imageId?: number | null
  parentId?: number | null
  parent?: Category | null
}