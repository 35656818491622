export const orderStatuses = [
  "Заказ", "Доставка", "Продажа", "Возврат", 
  "Отказ", "Отмена", "Предзаказ", "Обмен", "Кидок"
]
export const failStatuses = [
  "Возврат", "Возврат в пути", "Возврат прибыл", "Возврат получен", "Отказ", "Кидок"
]
export const failReasons = [
  "Размер", "Ошибка размер", "Ошибка модель", "Брак", 
  "Качество", "Не явился", "Не соответствует описанию"
]
export const payoutStatuses = [
  "Не выплачено", "Запрос", "Подтверждено", "Выплачено", "Отклонено", "Кидок"
]
export interface IHash {
  [details: string] : string;
} 
export const smsStatuses: IHash = {
  "ENROUTE": "сообщение отправлено",
  "DELIVRD": "сообщение доставлено",
  "EXPIRED": "истек срок жизни, сообщение не доставлено",
  "UNDELIV": "невозможно доставить сообщение",
  "REJECTD": "сообщение отклонено системой (черный список или же другие фильтры)",
  "ERROR": "некорректный номер или недостаточно средств"
}

export const printTypes: IHash = {
  'zebra100': 'Маркировка 100x100 (PDF)',
  'zebra100html': 'Маркировка 100x100 (A4 4 шт.)',
  'zebra85': 'Маркировка 85x85 (PDF)',
  'zebra85html': 'Маркировка 85x85 (A4 6 шт.)',
  'large': 'Документ'
}

export const ttnTextStatuses = [
  'Ожидает поступления', 'В пути', 'В отделении', 
  'Получено', 'Получено (ожидает перевод)', 
  'Перевод выдан', 'Отказ получателя', 'Адрес изменён', 'Прекращено хранение',
  'Получено и создано ТТН обратной доставки', 
  'Удалено', 'Номер не найден'
]

export const deliveryServicesFormatted: IHash = {
  "nova_poshta": "Новая Почта",
  "ukr_poshta": "Укрпочта",
  "justin": "Justin",
  "meest": "Meest",
}

export const perPageOptions = [
  8, 20, 40, 60
]

export const ordersPerPageOptions = [
  10, 20, 30, 50, 100
]

export const payoutRequestStatuses = [
  'Не выплачено', 'Запрос', 'Подтверждено', 'Выплачено', 'Отклонено'
]

export const stockActionTypes: IHash = {
  'increment': 'Приход',
  'decrement': 'Списание',
}

export const balanceActionTypes: IHash = {
  'increment': 'Увеличить',
  'decrement': 'Уменьшить',
}

export const orderActionTypes: IHash = {
  'created': 'создал(а) заказ',
  'edited': 'отредактировал(а) заказ',
  'ttn_generated': 'сгенерировал(а) ТТН',
  'deleted': 'удалил(а) заказ',
  'status_changed': 'поменял(а) статус на',
  'restored': 'восстановила(а) заказ',
  'delivery_payment_received': 'отметил(а), что наложенный платёж получен',
  'delivery_payment_changed': 'изменил(а) сумму наложенного платежа',
  'dropshipper_payout_status_changed': 'поменял(а) статус выплаты дропшипперу на'
}

export const npMoneyTransferCheckReportStatuses: IHash = {
  'ok': 'ОК',
  'error': 'Расхождение',
  'not_found': 'Не найдено'
}

export const notifyDropshippersTypes: IHash = {
  'all': 'Все дропшипперы',
  'only_without_orders': 'Только дропшипперы без заказов',
  'only_with_orders': 'Только дропшипперы с заказами'
}

export const notifyBotTypes: IHash = {
  'regular': 'Общий бот @my_drop_bot',
  'personal': 'Персональный бот'
}

export const availabilitySyncTypes: IHash = {
  'horizontal': "Горизонтальная",
  'selective': "Выборочная"
}
