import axios, { CancelToken } from 'axios'
import Warehouse from '@/models/warehouse'
import Product from '@/models/product'
import Category from '@/models/category'
import City from '@/models/city'
import Street from '@/models/street'
import CategoriesData from '@/models/categoriesData'
import ProductsData from '@/models/productsData'
import RetailerPage from '@/models/retailerPage'
import Order from '@/models/order'

const appBase = process.env.VUE_APP_BASE_URL
const base = `${appBase}/pages`;


// export async function getUkrposhtaDeliveryCities(searchQuery: string) {
//   return (await axios.post(
//     `${appBase}/up_delivery/city`,
//     { searchQuery }
//   )).data.results as Array<City>
// }

// export async function getUkrposhtaDeliveryWarehouses(cityRef: string) {
//   return (await axios.post(
//     `${appBase}/up_delivery/warehouses`,
//     { cityRef }
//   )).data.results as Array<Warehouse>
// }

// export async function getDeliveryCities(searchQuery: string, homeDelivery?: boolean) {
//   return (await axios.post(
//     `${appBase}/np_delivery/city`,
//     { searchQuery, homeDelivery }
//   )).data.results as Array<City>
// }

// export async function getDeliveryWarehouses(city: string) {
//   return (await axios.post(
//     `${appBase}/np_delivery/warehouses`,
//     { city }
//   )).data.results as Array<Warehouse>
// }

// export async function getDeliveryStreets(city: string, cityRef: string, searchQuery: string) {
//   return (await axios.post(
//     `${appBase}/np_delivery/street`,
//     { 
//       city,
//       cityRef,
//       searchQuery
//     }
//   )).data.results as Array<Street>
// }

export async function getCategory(publicUrl: string, categoryId: number) {
  return (await axios.get(
    `${base}/${publicUrl}/categories/${categoryId}`
  )).data as Category
}

export async function getCategories(publicUrl: string, parentId?: number, page?: number, perPage?: number, searchQuery?: string) {
  return (await axios.get(
    `${base}/${publicUrl}/categories`,
    {
      params: {
        parentId,
        page,
        perPage,
        searchQuery
      }
    }
  )).data as CategoriesData
}

export async function getProducts(
  publicUrl: string, page?: number, perPage?: number, 
  categoryId?: number, searchQuery?: string,
  mode?: string, cancelToken?: CancelToken
) {
  return (await axios.get(
    `${base}/${publicUrl}/products`, {
      params: {
        categoryId,
        searchQuery,
        page,
        perPage,
        mode
      }, 
      cancelToken: cancelToken
    }
  )).data as ProductsData
}

export async function getProduct(publicUrl: string, productId: number) {
  return (await axios.get(
    `${base}/${publicUrl}/products/${productId}`
  )).data as Product
}

export async function getOrder(publicUrl: string, orderId: number) {
  return (await axios.get(
    `${base}/${publicUrl}/orders/${orderId}`
  )).data as Order
}

export async function getRetailerPage(publicUrl: string) {
  return (await axios.get(
    `${base}/${publicUrl}/settings`
  )).data as RetailerPage
}


export async function createOrder(publicUrl: string, order: Order) {
  return (await axios.post(
    `${base}/${publicUrl}/orders`, {
      ...order
    }
  )).data as Order
}

export async function getUkrposhtaDeliveryCities(searchQuery: string) {
  return (await axios.post(
    `${appBase}/ukrposhta_delivery/city`,
    { searchQuery }
  )).data.results as Array<City>
}

export async function getUkrposhtaDeliveryWarehouses(cityRef: string) {
  return (await axios.post(
    `${appBase}/ukrposhta_delivery/warehouses`,
    { cityRef }
  )).data.results as Array<Warehouse>
}

export async function getDeliveryCities(searchQuery: string, homeDelivery?: boolean) {
  return (await axios.post(
    `${appBase}/delivery/city`,
    { searchQuery, homeDelivery }
  )).data.results as Array<City>
}

export async function getDeliveryWarehouses(city: string) {
  return (await axios.post(
    `${appBase}/delivery/warehouses`,
    { city }
  )).data.results as Array<Warehouse>
}

export async function getDeliveryStreets(city: string, cityRef: string, searchQuery: string) {
  return (await axios.post(
    `${appBase}/delivery/street`,
    { 
      city,
      cityRef,
      searchQuery
    }
  )).data.results as Array<Street>
}
