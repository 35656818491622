









import { customer } from "./store"

export default {
  beforeCreate() {
    (this as any).$i18n.locale = customer.locale;
  }
};
