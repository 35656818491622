export interface IHash {
  [details: string] : string;
} 

export const promOrderStatuses: IHash = {
  "pending": "Новый",
  "received": "Принят",
  "delivered": "Выполнен",
  "canceled": "Отменен",
  "paid": "Оплаченный"
}

export const deliveryServicesFormatted: IHash = {
  "nova_poshta": "Новая Почта",
  "ukr_poshta": "Укрпочта"
}

export const deliveryServicesOptions = [
  { value: 'nova_poshta', text: 'Новая Почта' },
  { value: 'ukr_poshta', text: 'Укрпочта' },
]

export const perPageOptions = [
  8, 20, 60, 100, 200, 500
]

export const ordersPerPageOptions = [
  10, 20, 30, 50
]

export function deliveryServiceFormatted(deliveryService: string) {
  let _formatted = ""
  deliveryServicesOptions.forEach(option => {
    if (option.value == deliveryService) {
      _formatted = option.text
    }
  })
  return _formatted
}
