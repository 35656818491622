




















import Vue from 'vue'
import Component from "vue-class-component"
import { customer } from "@/store"
import { getFullImageUrl } from '@/utils/functions'
import OrderProduct from '@/models/orderProduct'
import OrderProductsTable from '@/components/orders/OrderProductsTable.vue'

@Component({
  components: {
    OrderProductsTable
  }
})
export default class CartModal extends Vue {
  getFullImageUrl = getFullImageUrl

  goShopping() {
    this.$bvModal.hide('cart-modal')
    if (customer.retailerPage) {
      this.$router.push(`/${customer.retailerPage.publicUrl}`)
    }
  }

  get total() {
    var _total = 0
    this.customer.products.forEach((product: OrderProduct) => {
      if (product.price)
        _total += product.price * product.amount
    });
    return _total
  }
  
  get customer() {
    return customer
  }

  orderProductChanged() {
    customer.setOrder(customer.order)
  }

  removeProduct(index: number) {
    customer.products.splice(index, 1)
    customer.setProducts(customer.products)
    this.$forceUpdate()
  }
}
