







import Vue from 'vue'
import Component from "vue-class-component"
import { customer } from "@/store"
import OrderProductsTable from '@/components/orders/OrderProductsTable.vue'

@Component({
  components: {
    OrderProductsTable
  }
})
export default class PaymentTypesModal extends Vue {
  get retailerPage() {
    return customer.retailerPage
  }
}
