export const emptyCity = (): City => ({
  titleRu: "",
  titleUa: "",
  ref: ""
})

export default interface City {
  titleRu: string
  titleUa: string
  ref: string
}