import { LocaleMessageObject, LocaleMessages } from "vue-i18n";
import { Locales } from "./locales";

import ru from "./ru.json";
import ua from "./ua.json";

export const messages: LocaleMessages = {
  [Locales.RU]: (ru as LocaleMessageObject),
  [Locales.UA]: (ua as LocaleMessageObject)
};

export const defaultLocale = Locales.RU;