







import Vue from 'vue'
import Component from "vue-class-component"
import { customer } from "@/store"
import OrderProductsTable from '@/components/orders/OrderProductsTable.vue'
import { deliveryServicesOptions } from '@/utils/constants'

@Component({
  components: {
    OrderProductsTable
  }
})
export default class DeliveryServicesModal extends Vue {
  deliveryServicesOptions = deliveryServicesOptions
  deliveryServices: Array<string> = []

  get retailerPage() {
    return customer.retailerPage
  }

  loadDeliveryServices() {
    if (this.retailerPage) {
      if (this.retailerPage.novaPoshta) {
        this.deliveryServices.push('nova_poshta')
      }
      if (this.retailerPage.ukrPoshta) {
        this.deliveryServices.push('ukr_poshta')
      }
    }
  }

  get filteredDeliveryServicesOptions() {
    var _options: Array<any> = []
    this.deliveryServicesOptions.forEach(option => {
      if (this.deliveryServices.includes(option.value)) {
        _options.push(option)
      }
    })
    
    return _options
  }

  mounted() {
    this.loadDeliveryServices()
  }
}
