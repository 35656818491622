









import Vue from 'vue'
import Component from "vue-class-component"
import OrderForm from "@/components/orders/OrderForm.vue"
import LayoutPublicProducts from "@/layouts/LayoutPublicProducts.vue"
import { Prop } from "vue-property-decorator"
import { customer } from '@/store'

@Component({
  components: {
    OrderForm,
    LayoutPublicProducts
  }
})

export default class OrderFormView extends Vue {
  @Prop() publicUrl!: string

  mounted() {
    if (customer.retailerPage)
      document.title = `${customer.retailerPage.name} | Оформить заказ`
  }
}
