import Product from "./product";
import ProductSize from "./productSize";

export const emptyOrderProduct = (product: Product, size: ProductSize): OrderProduct => ({
  product: product,
  size: size,
  amount: 1
})

export default interface OrderProduct {
  product: Product
  size: ProductSize
  amount: number
  price?: number
}