










































































































import Vue from 'vue'
import Component from "vue-class-component"
import Product from '@/models/product'
import { customer } from '@/store'
import ProductSize from '@/models/productSize'
import { Prop } from "vue-property-decorator"
import ProductsSetPopover from "@/components/products/ProductsSetPopover.vue"
const moment = require('moment')
import { getFullImageUrl } from '@/utils/functions'
import { getProduct } from '@/utils/customerApi'
import OrderProduct, { emptyOrderProduct } from '@/models/orderProduct'
import ShowImageModal from "@/components/products/ShowImageModal.vue"

@Component({
  components: {
    ProductsSetPopover,
    ShowImageModal
  }
})
export default class PublicProductData extends Vue {
  @Prop() publicUrl!: string
  @Prop() productId!: number
  
  loading = true
  product: Product | null = null
  productSizeId: number | null = null
  
  getFullImageUrl = getFullImageUrl

  slideIndex = 0

  get retailerPage() {
    return customer.retailerPage
  }

  addToOrder(product: Product, size: ProductSize) {
    let found = false
    customer.products.forEach((orderProduct: OrderProduct) => {
      if (orderProduct.size.id == size.id) {
        if (orderProduct.size.realCountDropshipper && orderProduct.amount + 1 <= orderProduct.size.realCountDropshipper) {
          orderProduct.amount += 1
          customer.setProducts(customer.products)
        }
        found = true
      }
    });
    if (!found) {
      var orderProduct = emptyOrderProduct(product, size)
      orderProduct.price = product.price
      customer.products.push(orderProduct)
      customer.setProducts(customer.products)
    }
    this.$bvModal.show('cart-modal')
  }

  showSizeChartImage() {
    this.$bvModal.show('show-image-modal')
  }

  nextSlide() {
    this.slideIndex += 1
    if (this.product && this.slideIndex > this.product.images.length) {
      this.slideIndex = 0
    }
  }

  prevSlide() {
    this.slideIndex -= 1
    if (this.product && this.slideIndex < 0) {
      this.slideIndex = this.product.images.length - 1
    }
  }

  get moment() {
    return moment
  }

  sizeCellColor(realCountDropshipper: number) {
    return realCountDropshipper > 0 && this.retailerPage && this.retailerPage.highlightAvailability ? `table-${this.retailerPage.buttonsColor}`: ''
  }

  makeOrderButtonDisabled(size: ProductSize) {
    return !size.availableDropshipper
  }

  realCountDropshipper(product: Product, size: ProductSize) {
    var realCountDropshipper = size.realCountDropshipper
    if (this.retailerPage && (this.retailerPage.hiddenStock || product.hiddenStock)) {
      if (realCountDropshipper && realCountDropshipper > 0) {
        return this.retailerPage.stockAvailableText
      } else {
        return this.retailerPage.stockUnavailableText
      }
    } else {
      return realCountDropshipper
    }
  }

  openSubcategory(categoryId: number) {
    this.$router.push(this.categoryLink(categoryId))
    window.scrollTo(0,0)
  }

  categoryLink(categoryId: number) {
    if (categoryId == -1) {
      return `/${this.publicUrl}`
    } else {
      return `/${this.publicUrl}/c/${categoryId}`
    }
  }

  get breadcrumbItems() {
    var _breadcrumbItems = []
    if (this.product && this.product.category) {
      var parent: any = this.product.category
      while(parent) {
        _breadcrumbItems.unshift(parent)
        parent = parent.parent
      }
    }
    return _breadcrumbItems
  }

  async getProduct() {
    try {
      var product = await getProduct(this.publicUrl, this.productId)
      if (product.id)
        this.product = product
    } catch {
      console.log("Product not found")
    }
  }

  async mounted() {
    this.loading = true
    await customer.getRetailerPage(this.publicUrl)
    await this.getProduct()
    this.loading = false
  }
}
