export const emptyWarehouse = (): Warehouse => ({
  titleRu: "",
  titleUa: "",
  number: "",
  warehouseRef: "",
  cityRef: "",
  category: ""
})

export default interface Warehouse {
  titleRu: string
  titleUa: string
  number: string
  warehouseRef: string
  cityRef: string
  category: string
}