import Order, { emptyOrder } from '@/models/order'
import OrderProduct from '@/models/orderProduct'
import RetailerPage from '@/models/retailerPage'
import { getRetailerPage } from '@/utils/customerApi'
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'

@Module({ name: 'customer', namespaced: true })
export default class extends VuexModule {
  locale = "ua"
  productsPerPage: number = 20
  categoriesPerPage: number = 20
  productsDisplayMode: string = "gallery"
  retailerPage: RetailerPage | null = null
  order: Order = emptyOrder()
  products: Array<OrderProduct> = []

  @Mutation
  setOrder(order: Order) {
    this.order = order
  }

  @Mutation
  setProducts(products: Array<OrderProduct>) {
    this.products = products
  }

  @Mutation
  changeLocale(locale: string) {
    this.locale = locale
  }

  @Mutation
  setProductsPerPage(perPage: number) {
    this.productsPerPage = perPage
  }

  @Mutation
  setRetailerPage(retailerPage: RetailerPage) {
    this.retailerPage = retailerPage
  }

  @Mutation
  setProductsDisplayMode(value: string) {
    if (['gallery', 'table'].includes(value))
      this.productsDisplayMode = value
  }

  @Mutation
  setCategoriesPerPage(perPage: number) {
    this.categoriesPerPage = perPage
  }

  @Mutation
  clearProducts() {
    this.products = []
  }

  // @Action({commit: 'setRetailerPage'})
  @Action
  async getRetailerPage(publicUrl: string) {
    let retailerPage = await getRetailerPage(publicUrl)
    if (this.retailerPage && this.retailerPage.publicUrl != retailerPage.publicUrl) {
      this.setProducts([])
    }
    this.setRetailerPage(retailerPage)
  }
}

