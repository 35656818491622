










import Vue from 'vue'
import Component from "vue-class-component"
import Navbar from "@/components/Navbar.vue"
import ContactsModal from '@/components/orders/ContactsModal.vue'
import CartModal from '@/components/orders/CartModal.vue'
import Footer from '@/components/Footer.vue'

@Component({
  components: {
    Navbar,
    ContactsModal,
    CartModal,
    Footer
  }
})
export default class LayoutPublicProducts extends Vue {
}
