













import { customer } from "@/store"
import Vue from "vue"
import Component from "vue-class-component"

@Component
export default class Footer extends Vue {
  get currentLanguage() {
    return this.$i18n.locale
  }
  
  changeLocale(locale: string) {
    customer.changeLocale(locale)
    this.$i18n.locale = locale
  }
}
