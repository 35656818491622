









import Vue from 'vue'
import Component from "vue-class-component"
import ProductsNavigation from "@/components/products/Navigation.vue"
import LayoutPublicProducts from "@/layouts/LayoutPublicProducts.vue"
import { Prop } from "vue-property-decorator"
import { customer } from '@/store'

@Component({
  components: {
    ProductsNavigation,
    LayoutPublicProducts
  }
})

export default class ProductsCategoriesView extends Vue {
  @Prop() publicUrl!: string

  mounted() {
    if (customer.retailerPage)
      document.title = `${customer.retailerPage.name} | Категории`
  }
}
