export const emptyStreet = (): Street => ({
  titleRu: "",
  titleUa: "",
  ref: ""
})

export default interface Street {
  titleRu: string
  titleUa: string
  ref: string
}