








































import Vue from 'vue'
import Component from "vue-class-component"
import { customer } from '@/store'
import OrderProduct from '@/models/orderProduct'
import { getFullImageUrl } from '@/utils/functions'
import { Prop } from 'vue-property-decorator'

@Component
export default class OrderProductsTable extends Vue {
  @Prop({default: true}) editable!: boolean
  @Prop() products!: Array<OrderProduct>
  productsList: Array<OrderProduct> = []
  
  customer = customer
  getFullImageUrl = getFullImageUrl

  get retailerPage() {
    return customer.retailerPage
  }
  
  orderProductChanged() {
    customer.setOrder(customer.order)
  }

  removeProduct(index: number) {
    customer.products.splice(index, 1)
    customer.setProducts(customer.products)
    this.$forceUpdate()
  }

  get total() {
    var _total = 0
    this.productsList.forEach((product: OrderProduct) => {
      if (product.price)
        _total += product.price * product.amount
    });
    return _total
  }

  mounted() {
    if (!this.products) {
      this.productsList = customer.products
    } else {
      this.productsList = this.products
    }
  }
}
