









import Vue from 'vue'
import Component from "vue-class-component"
import Order from "@/components/orders/Order.vue"
import LayoutPublicProducts from "@/layouts/LayoutPublicProducts.vue"
import { Prop } from "vue-property-decorator"
import { customer } from '@/store'

@Component({
  components: {
    Order,
    LayoutPublicProducts
  }
})

export default class OrderView extends Vue {
  @Prop() publicUrl!: string
  @Prop() orderId!: number

  mounted() {
    if (customer.retailerPage)
      document.title = `${customer.retailerPage.name} | Ваш заказ`
  }
}
