import Vue from 'vue'
import VueRouter from 'vue-router'
import { customer } from '@/store'
import { i18n } from "@/main"
import ProductsGallery from '@/views/products/Gallery.vue'
import ProductsCategories from '@/views/products/Categories.vue'
import Product from '@/views/products/Product.vue'
import OrderForm from '@/views/orders/OrderForm.vue'
import Order from '@/views/orders/Order.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:publicUrl',
    component: ProductsCategories,
    props: true
  },
  {
    path: '/:publicUrl/c/:categoryId',
    component: ProductsGallery,
    props: true
  },
  {
    path: '/:publicUrl/p/:productId',
    component: Product,
    props: true
  },
  {
    path: '/:publicUrl/orders/new',
    component: OrderForm,
    props: true
  },
  {
    path: '/:publicUrl/orders/:orderId',
    component: Order,
    props: true
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to: any, _, next) => {
  if (to.meta.title) {
    document.title = i18n.t(to.meta.title, customer.locale) as string
  }
  next()
})

export default router
